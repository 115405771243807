import '@fontsource/roboto';
import { Element } from 'react-scroll';
import { ImageCarousel } from './components/ImageCarousel';

function Statement(props) {
  return (
        <Element id={props.id}>
            <h1>Statement</h1>
            <p>As a 2022 IEEE-HKN president-elect candidate, I humbly seek your support and vote in this election. IEEE-HKN has been an integral part of my professional and leadership journey. As a student, IEEE-HKN provided a strong path to follow my passion in serving differently-abled children, impoverished students, and women in developing nations. Today as a leading technologist, IEEE-HKN’s core values and volunteering experience strongly shaped my vision and passion for serving under-served communities. These values and volunteering experience empowered me to invent technological solutions and deliver global programs with profound impact in improving the living conditions of marginalized communities globally.</p>
            <p><strong>I strongly believe that "Students, chapter advisers, alumni, and professional members are the pillars of IEEE-HKN. Without you, the story of HKN's progress and growth is not complete".</strong> My mission is to serve the needs of the HKN chapters and deliver unique member-enriching experiences at the grassroots. In the last 16 years, I have served in 20+ IEEE global boards/committees and have delivered high-impactful member engagement global programs.</p>
            <p>I’m committed to building a vibrant life-long global community that will inspire, connect, and engage HKN members to advance technology for the benefit of humanity and deliver an inclusive and prosperous future for everyone. I will launch a portfolio of high-impactful programs that will promote career development, increase transnational networking opportunities, strengthen partnerships with industry and global organizations, and make IEEE-HKN one of the world’s top trusted sources in technology and innovations. </p>
            <ImageCarousel images={["carousel-3.png","powerafrica.png","carousel-1.png", 'hkn-slc-2019.png', "kalam.png", "awards-2013-hkn.jpg"]}/>
            <p><strong>My top priorities for IEEE-HKN members are as follows:</strong></p>

            <h2><strong>Deliver strategic global programs and partnerships to enrich the HKN membership experience</strong></h2>
            <ul>
                <li><strong>Launch joint programs between HKN and IEEE global programs</strong> like IEEE MGA, Technical societies, Standards, and EAB to foster shared vision and stronger collaboration at the grassroots.</li>
                <li><strong>Establish IEEE-HKN regional council to deliver unique region-oriented programs.</strong> Rollout regional HKN chapter summits, student and Alumni leadership conferences to strengthen member engagement, expand networking opportunities, and chapter growth.</li>
                <li><strong>Introduce IEEE-HKN technology-based sustainable development programs</strong> in partnership with global organizations to empower HKN members to apply their technical expertise in addressing global challenges faced by underserved communities.</li>
                <li><strong>Establish a community of practices among HKN chapters</strong> to share best practices, lessons learned, and success stories in reactivating dormant chapters.</li>
                <li><strong>Offer career-relevant educational programs</strong> to strengthen the professional skill development of HKN members through online conferences, webinars, workshops, and training courses in IEEE Learning Network.</li>
                <li><strong>Provide transnational micro-volunteering opportunities</strong> to increase HKN member engagement and serve their emerging career needs.</li>
            </ul>


            <h2><strong>Foster career-relevant skill development programs to benefit students, young professionals, and alumni members</strong></h2>
            <ul>
                <li><strong>Launch strategic leadership and career guidance programs</strong> to train and transform HKN students and early-career professionals into world-class leaders.</li>
                <li><strong>Launch an industry connection program</strong> to prepare HKN student members for the industry workforce. Offer skill development on emerging technologies through hands-on boot camps, provide career counseling, and internship opportunities.</li>
                <li><strong>Launch unique partnership programs with IEEE humanitarian activities</strong> to empower HKN students and professional members to leverage their technical skills for social good.</li>
                <li><strong>Increase donor support</strong> for student scholarships by strengthening donor outreach and awareness of the impactful work carried out by HKN chapters.</li>
                <li><strong>Deliver a global entrepreneurship program</strong> to train HKN members and create future leaders to drive innovations.</li>
                <li><strong>Launch a member spotlight program</strong> to increase public awareness of HKN and showcase the impactful technological work carried out by HKN members.</li>
            </ul>

            <h2><strong>Offer unique global member engagement opportunities for leadership growth </strong></h2>
            <ul>
                <li><strong>Launch a global HKN mentorship program</strong> to connect student and Alumni members with academic and Industrial HKN leaders to provide career guidance.</li>
                <li><strong>Create an industry consortium involving key industry leaders and HKN professional members</strong>to strengthen the partnership between HKN and the industry.</li>
                <li><strong>Establish a global chapter/faculty advisor forum</strong> to bring together advisors and facilitate information exchanges on chapter best practices and excellence.</li>
                <li><strong>Create an alumni engagement program</strong> to foster opportunities to re-connect, network, collaborate on professional development activities.</li>
                <li><strong>Launch a global recognition program</strong> to highlight the outstanding contributions of chapters, advisors, and alumni members.</li>
            </ul>
            
            <h2><strong>Strengthen and scale-up IEEE-HKN chapter development and growth - "Think globally and act locally"</strong></h2>
            <ul>
                <li><strong>Deliver impactful member engagement opportunities</strong> to sustain the growth of existing HKN chapters.</li>
                <li><strong>Expand partnership with IEEE regions</strong> to sustain engagement and scale-up chapter growth in regions with limited presence.</li>
                <li><strong>Launch a global HKN chapter support program</strong> to facilitate close interaction with HKN Board in regular cadence to seek guidance on chapter operational excellence.</li>
                <li><strong>Foster cross-regional collaboration among HKN chapters</strong> and expand networking opportunities.</li>
                <li><strong>Collaborate with IEEE-IT to provide self-serve tools</strong> to simplify annual reporting, chapter health monitoring, and awards nomination.</li>
            </ul>
        </Element>
  );
}

export default Statement;
