import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { Container, Tab, Tabs, Typography } from '@material-ui/core';
import { Link } from 'react-scroll';
import Hidden from '@material-ui/core/Hidden';

export function NavBar(props) {
  const [value, setValue] = React.useState(0);

  return (
    <AppBar position="fixed" color="default">
      <Container fixed>
        <Toolbar>
        <Grid
      justify="space-between" // Add it here :)
      container 
      spacing={24}
    >
      <Grid item>
        <Typography
          variant="h6"
          component="h2"
          style={{
            textAlign: "center",
            fontWeight: 300,
            whiteSpace: "nowrap",
            paddingTop: 10
          }}
        >
          Sampath<Hidden xsDown>kumar Veeraraghavan</Hidden> for HKN President-Elect <Hidden xsDown>2022</Hidden>
        </Typography>
      </Grid>
<Hidden xsDown>
      <Grid item>
        <Tabs
          value={value}
          // onChange={() => {
          //   setValue(newValue);
          // }}
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
        >
          <Link
            activeClass="active"
            to="section-1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(0);
            }}
          >
            <Tab label="Home" />
          </Link>
          <Link
            activeClass="active"
            to="section-2"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(1);
            }}
          >
            <Tab label="Statement" />
          </Link>
          <Link
            activeClass="active"
            to="section-3"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(2);
            }}
          >
            <Tab label="Biography" />
          </Link>
          <Link
            activeClass="active"
            to="section-4"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(3);
            }}
          >
            <Tab label="Activities" href="#activities" />
          </Link>
          <Link
            activeClass="active"
            to="section-5"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(4);
            }}
          >
            <Tab label="Honors &amp; Awards" href="#honorsandawards" />
          </Link>
          {/* <Link
            activeClass="active"
            to="section-6"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(5);
            }}
          >
            <Tab label="Gallery" href="#gallery" />
          </Link>
          <Link
            activeClass="active"
            to="section-7"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(6);
            }}
          >
            <Tab label="Endorsements" href="#endorsements" />
          </Link> */}
          {/* <Link
            activeClass="active"
            to="section-8"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => {
              setValue(7);
            }}
            onClick={() => {
              setValue(7);
            }}
          >
            <Tab label="Feedback" href="#feedback" />
          </Link> */}
        </Tabs>
      </Grid>
      </Hidden>
    </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
