import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#f5f5f5',
      contrastText: '#f5f5f5',
    },
    secondary: {
      main: '#005e97',
    },
  },
});
