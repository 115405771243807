import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Hidden from '@material-ui/core/Hidden';

export function ImageCarousel(props) {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    dots: true
  };
  return (
    <div style={{ marginBottom: '50px' }}>
      <Hidden lgUp>
      <Slider {...settings}>
        {props.images.map((image) => (
          <div>
            <img
              src={`./images/${image}`}
              alt=""
              style={{
                margin: "auto",
                width: "auto",
                maxWidth: "100%",
                height: "auto",
                maxHeight: "400px"
              }}
            />
          </div>
        ))}
      </Slider>
      </Hidden>
      <Hidden mdDown>
      <Slider
        centerMode
        infinite
        dots
        centerPadding="60px"
        slidesToShow={2.3}
        speed={500}
        autoplay
        autoplaySpeed={2000}
        cssEase="linear"
        variableWidth={true}
        adaptiveHeight={true}
      >
        {props.images.map((image) => (
          <div>
            <img
              src={`./images/${image}`}
              style={{
                backgroundSize: 'cover',
                objectPosition: 'center',
                pointerEvents: 'none',
                height: "400px",
                width: "auto"
              }}
              alt=""
            />
          </div>
        ))}
      </Slider>
      </Hidden>
    </div>
  );
}
