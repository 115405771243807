import '@fontsource/roboto';
import { theme } from './components/theme/main';
import { ThemeProvider } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { NavBar } from './components/navigation/Navbar';
import { Footer } from './components/Footer';
import { Element } from 'react-scroll';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ImageCarousel } from './components/ImageCarousel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Statement from './Statement';
import Biography from './Biography';

const downloadFlyer = () => {
  const link = document.createElement("a");
  link.download = `SampathV-for-HKN-President-2022.pdf`;
  link.href = "./pdf/flyer.pdf";
  link.click();
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Container style={{
          marginTop: '100px',
          height: '100%',
        }} fixed>
        <Element id="section-1">
          <img src="./images/banner.png" alt=""/>
          <Button color="secondary" variant="contained" onClick={downloadFlyer} fullWidth>
            Download Flyer
          </Button>
          <video style={{width: "100%", height: "auto"}} controls>
            <source src="http://ieeetv.ieee.org/ns/ieeetvdl/2021/HKN-SLC-2021/Candidate_HKN-Sampath_V.mp4" type="video/mp4"/>
          </video>
          <hr/>
        </Element>
        <Statement id="section-2"/>
        <Biography id="section-3"/>
        <Element id="section-4">
          <h1>Activites</h1>
          <p>In his 16 years of volunteering journey, he has spearheaded and delivered numerous high-impactful programs which significantly scaled up the growth of several global IEEE programs &amp; committees:</p>
          <List style={{backgroundColor: theme.palette.background.paper}}>
            {["IEEE HKN", "IEEE History Committee", "IEEE Humanitarian Activities Committee", "IEEE Special Interest Group on Humanitarian Technologies (SIGHT)", "IEEE EMCC", "IEEE Public Visibility Initiatives", "IEEE Public Visibility Initiatives", "IEEE Young Professionals", "IEEE Women in Engineering", "IEEE Standards and IEEE Engineering Projects in Community Service (EPICS)"].map((activity) => (
              <ListItem>
                  <ListItemText>{activity}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Element>
        <Element id="section-5">
          <h1>Honors &amp; Awards</h1>
          <p>Sampath’s exemplary scholarly contributions and leadership excellence accredited him with numerous global honors and media mentions:</p>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>IEEE Theodore W. Hissey Outstanding Young Professional Award (2020)</AccordionSummary>
            <AccordionDetails>
              <iframe src="//ieeetv.ieee.org/player/embed_play/133238/auto" title="IEEE Theodore W. Hissey Outstanding Young Professional Award (2020)" allowfullscreen frameborder="0" scrolling="no" width="100%" height="400px"></iframe>
              <br/>
              {/* <img src="./images/awards-2020-hissey.jpg" alt="IEEE Theodore W. Hissey Outstanding Young Professional Award"></img> */}
            </AccordionDetails>
          </Accordion>
          <List style={{backgroundColor: theme.palette.background.paper}}>
            {["IEEE Global Larry K. Wilson Transnational Award (2016)", "IEEE-USA Professional Achievement Award (2014)", "IEEE-HKN Outstanding Young Professional Award (2013)", "IEEE Global MGA Achievement Award (2008 & 2013)", "IEEE USA and National Engineers’ Week Foundation’s “New Faces of Engineering” (2011)", "IEEE Presidents’ “Change the World” Award"].map((activity) => (
              <ListItem>
                  <ListItemText>{activity}</ListItemText>
              </ListItem>
            ))}
          </List>
          <ImageCarousel images={["awards-2020-hissey2.JPG", "awards-2011-new_faces.png","awards-2010-change_the_world.jpg","awards-2009-mga.png"]}/>
        </Element>
        {/* <Element id="section-6">
          <h1>Gallery</h1>
        </Element> */}
        {/* <Element id="section-7">
          <h1>Endorsements</h1>
        </Element> */}
        {/* <Element id="section-8">
          <h1>Feedback</h1>
        </Element> */}
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
